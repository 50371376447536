import styled from "styled-components"
import background from "@/public/header-background.svg"
import { Button } from "@mui/material"
import { ArrowRight } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Link } from "next-translate-routes"
import { useSession } from "next-auth/react"
import TagManager from "react-gtm-module"
import { useContext } from "react"
import { Context } from "@/context/state"
import { MdOutlinePhone } from "react-icons/md"
import posthog from "posthog-js"
import Script from "next/script"

const CTA = () => {
  const { data: session } = useSession()
  const { dispatch } = useContext(Context)

  const handleScheduleClick = e => {
    if (window.Calendly) {
      window.Calendly.initPopupWidget({
        url: "https://calendly.com/joep-valvest/introduction-to-valvest-clone?hide_gdpr_banner=1",
      })
    }
  }

  return (
    <Wrapper>
      <Script
        strategy="afterInteractive"
        src="https://assets.calendly.com/assets/external/widget.js"
      />
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="cta-content">
              {!session?.id ? (
                <>
                  <h5>{translate("homepage_CTA_title")}</h5>
                  <p>{translate("homepage_CTA_description")}</p>
                  <div
                    className="mx-auto"
                    style={{ maxWidth: "500px", width: "100%" }}
                  >
                    <div className="d-flex flex-column flex-md-row justify-content-center gap-2 mt-4 p-relative">
                      <Button
                        variant="outlined"
                        className="rounded-pill d-flex align-items-center w-100 w-md-auto"
                        size="large"
                        style={{
                          color: "white",
                          border: "1px solid white",
                        }}
                        onClick={() => {
                          handleScheduleClick()
                          posthog.capture("plan_call")
                        }}
                      >
                        {translate("homepage_header_secondary_button_test")}
                        <MdOutlinePhone size="20" className="ms-1" />
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch({ type: "SET_LOGIN_MODAL" })
                        }}
                        variant="outlined"
                        className="rounded-pill d-flex align-items-center w-100 w-md-auto"
                        size="large"
                        style={{ background: "white" }}
                      >
                        {translate("homepage_CTA_button")}
                        <ArrowRight className="ms-2" />
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h5>{translate("homepage_CTA_title_signed_in")}</h5>
                  <p>{translate("homepage_CTA_description_signed_in")}</p>
                  <div
                    className="mx-auto"
                    style={{ maxWidth: "500px", width: "100%" }}
                  >
                    <div className="d-flex flex-column flex-md-row justify-content-center gap-2 mt-4 p-relative">
                      <Button
                        variant="outlined"
                        className="rounded-pill d-flex align-items-center w-100 w-md-auto"
                        size="large"
                        style={{
                          color: "white",
                          border: "1px solid white",
                        }}
                        onClick={() => {
                          handleScheduleClick()
                          posthog.capture("plan_call")
                        }}
                      >
                        {translate("homepage_header_secondary_button_test")}
                        <MdOutlinePhone size="20" className="ms-1" />
                      </Button>
                      <Button
                        variant="outlined"
                        className="rounded-pill d-flex align-items-center w-100 w-md-auto"
                        size="large"
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        {translate("homepage_CTA_button_signed_in")}
                        <ArrowRight className="ms-2" />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CTA

const Wrapper = styled.div`
  position: relative; /* Ensure positioning context for :before */
  padding: 4rem 0;
  background: #fb8500;
  color: white;
  text-align: center;
  overflow: hidden; /* Ensure background doesn't overflow */

  h5 {
    font-size: 1.75rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.4rem;
      line-height: 2.3rem;
    }
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1rem;
      padding: 0 0.5rem;
    }
  }

  .container:before {
    content: "";
    background-image: url(${background.src});
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    top: -200px;
    scale: 3;
    rotate: -20deg;
  }

  a {
    text-decoration: none;
  }
`
